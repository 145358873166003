@import "src/assets/tokens";
.eventTitle {
  @include type-sm-bold;
  line-height: 12px;
  padding-right: 0.005rem;
  padding-left: 0.2rem;
}
.time p, .icon {
  @include type-sm;
  line-height: 11px;
}
.isFull {
  @include type-sm-bold;
  font-size: 9px;
  line-height: 10px;
  text-align: right;
  padding-right: 0.05rem;
  padding-left: 0;
}
.icon {
  padding-right: 0.2rem;
  padding-left: 0;
  margin-bottom: 1px;
}
.time{
  padding: 0  0 0 0.2rem;
}
.checkbox {
  padding: 0  0.2rem 0 0;
  input {
    margin: 0 2px 0 0;
  }
}
.tooltip{
  padding: 5px;
}
.tooltipTitle {
@include type-md-bold;
}

@media (max-width: 767px) {
  .eventTitle{
    font-size: 9px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .time p, .icon {
    font-size: 9px;
    line-height: 10px;
  }
  .time, .checkbox{
     padding: 0;
   }
}
