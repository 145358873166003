@import "src/assets/tokens";
.btn {
  @include type-button-md;
  border-radius: 3px;
  text-align: center;
  margin: 0 10px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  box-shadow: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding:8px 10px;
  align-items: center;
  &.btnIcon {
    margin: 0;
    padding: 7px 10px;
  }
}

.btnPrimary {
  border:1px solid $primary-500;
  background: $primary-500;
  color: $grayscale-000;
  svg {
    fill: $grayscale-000;
    margin-left: 10px;
    margin-bottom: -2px;
  }
  &:hover, &:active, &:focus{
    border:1px solid $primary-400;
    background: $primary-400;
    color: $grayscale-000;
  }
  &.btnIcon {
    &:hover, &:active, &:focus{
      svg {
        fill:  $grayscale-000;
      }
    }
  }
   &:disabled {
     border:1px solid $grayscale-300;
     background: $grayscale-300;
     color: $grayscale-000;
     cursor: auto;
     &:hover, &:active, &:focus{
        border:1px solid $grayscale-300;
        background: $grayscale-300;
        color: $grayscale-000;
        cursor: auto;
     }
   }
}

.btnSecondary {
  border:1px solid $primary-400;
  background: $grayscale-000;
  color: $primary-400;
  svg {
    fill: $primary-400;
  }
  &:hover, &:active, &:focus{
    background: $primary-400;
    color: $grayscale-000;
  }
  &:hover, &:active, &:focus{
    svg {
      fill:  $grayscale-000;
    }
  }
  &:disabled {
    border:1px solid $grayscale-200;
    background: $grayscale-100;
    color: $grayscale-300;
    cursor: auto;
    svg {
        fill: $grayscale-300;
      }
    &:hover, &:active, &:focus{
      border:1px solid $grayscale-200;
      background: $grayscale-100;
      color: $grayscale-300;
      cursor: auto;
    }
  }
}
