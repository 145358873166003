@use "sass:math";
@import "flexboxgrid";
@import "assets/tokens";

@font-face{
  font-family: "Avenir";
  font-weight: 400;
  src: url("assets/fonts/b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2") format("woff2"),
  url("assets/fonts/4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff") format("woff");
}
/*Avenir® W01 85 Heavy*/
@font-face{
  font-family: "Avenir";
  font-weight: 800;
  src: url("assets/fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"),
  url("assets/fonts/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff");
}

html {
  font-size: 14px;
}
body {
  margin: 0;
  @include type-md;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .row{
    margin: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1{
  @include type-h1;
  margin: 0;
}
h2{
  @include type-h2;
  margin: 0;
}
h3{
  @include type-h3;
  margin: 0;
}
h4{
  @include type-h4;
  margin: 0;
}
h5{
  @include type-h5;
  margin: 0;
}
h6{
  @include type-h6;
  margin: 0;
}
p{
  @include type-md;
  margin: 0;
}
.page{
  min-height: 100vh;
}

// Overwriting react big calendar default variables.
$out-of-range-color: $grayscale-500;
$out-of-range-bg-color: $grayscale-300;

$calendar-border: $grayscale-200;
$cell-border: $grayscale-200;

$border-color: $grayscale-300;

$segment-width: percentage(math.div(1, 7));

$time-selection-color: $grayscale-100;
$time-selection-bg-color: $grayscale-300;
$date-selection-bg-color: $grayscale-300;

$event-bg: $primary-500;
$event-border: $primary-500;
$event-outline: $primary-500;
$event-color: $grayscale-000;
$event-border-radius: 2px;
$event-padding: 2px 5px;
$event-zindex: 4;

$btn-color: $primary-500;
$btn-bg: $grayscale-000;
$btn-border: $primary-300;

$current-time-color: $brand-400;

$rbc-css-prefix: rbc-i;

$today-highlight-bg: $primary-100;

@import 'react-big-calendar/lib/sass/styles';

.rbc-day-slot .rbc-events-container{
  margin-right: 0;
}
.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
  flex-flow: column;
  padding: 2px 0;
}
.rbc-day-slot .rbc-event-label{
  display: none;
}
.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event{
  background: $primary-300;
}

.rbc-event{
  .checkMark {
    width: 13px;
    height: 13px;
    border: 1px solid $grayscale-200;
    margin: 0 0 0 auto;
    background: $grayscale-000;
    position: relative;
    border-radius: 2px;
  }
  &.selected{
    background: $success-400;
    border: 1px solid $success-400;
    outline: $success-400;
    .checkMark{
      background: $grayscale-000;
      border: 1px solid $grayscale-000;
      &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 4px;
        width: 4px;
        height: 9px;
        transform: rotate(45deg);
        border: solid $success-400;
        border-width: 0 2px 2px 0;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
    &.waitList {
      background: $alert-400!important;
      border: 1px solid $alert-400!important;
      outline: $alert-400;
      .checkMark{
        &:after{
          border: solid $alert-400;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }
  &.notBookable, &.maxLimit{
    background: $grayscale-200!important;
    border: 1px solid $grayscale-200!important;
    outline: $grayscale-200;
    &:hover{
      cursor: not-allowed;
    }
    .checkMark{
      &:after{
        content: '\2215';
        position: absolute;
        left: 4px;
        bottom: -3px;
      }
    }
  }
}

@media (max-width: 767px) {
  .rbc-event {
    .checkMark{
      width: 10px;
      height: 10px;
    }
    &.selected{
      .checkMark{
        &:after {
          left: 3px;
          top: -1px
        }
      }
    }
  }
}
