.formContainer {
  padding: 1rem;
  max-width: 570px;
  margin: 0 auto;
  .title{
    text-align: center;
    margin: 50px auto;
  }
}
.buttonContainer {
  text-align: center;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .formContainer{
    height: calc(100vh - 110px);
  }
}
