.btnGroup {
  padding-right: 0;
}
.toolBarContainer {
  padding-bottom: 1rem;
}

@media (max-width: 767px) {
  .toolBarContainer {
    text-align: center;
  }
  .btnGroup{
    padding-left: 0;
    margin: 10px auto;
  }
}
