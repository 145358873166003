@import "src/assets/tokens";
.tab {
  width: 100%;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 1rem;
  background: transparent;
  cursor: pointer;
  color: $grayscale-700;
  @include type-md-bold;
  .number {
    color: $grayscale-000;
    background: $primary-400;
    width: 40px;
    min-height: 40px;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 10px;
  }
  &:disabled{
    cursor: default;
    color: $grayscale-400;
    @include type-md;
    .number{
      background: $primary-200;
      color: $grayscale-400;
    }
  }
}
