// ***** Color Tokens *****
// Primary
$primary-100: #F2F6FD;
$primary-200: #EAF0FB;
$primary-300: #BBD5F4;
$primary-400: #4A90E2;
$primary-500: #32629B;
$primary-600: #16284A;

//Brand
$brand-100:#F0ECFE;
$brand-200:#E2DBFD;
$brand-300:#8D73F8;
$brand-400:#6744F6;
$brand-500:#3F26A8;
$brand-600:#1E0564;

//Grayscale
$grayscale-000:#FFFFFF;
$grayscale-100:#F2F2F2;
$grayscale-200:#E0E0E0;
$grayscale-300:#BDBDBD;
$grayscale-400:#828282;
$grayscale-500:#4F4F4F;
$grayscale-600:#333333;
$grayscale-700:#000000;

//Status Tones

//Warnings
$warning-100:#FDF3F3;
$warning-200:#F5CFCF;
$warning-300:#F2666D;
$warning-400:#EE333C;
$warning-500:#B20F17;
$warning-600:#590700;

// Alert
$alert-100:#FCEAC6;
$alert-200:#FFD78F;
$alert-300:#FFC356;
$alert-400:#FFAF1E;
$alert-500:#BE7A00;
$alert-600:#5F3D00;

// Success
$success-100:#DDEAD7;
$success-200:#BDE294;
$success-300:#9CD45E;
$success-400:#7ABC32;
$success-500:#517D21;
$success-600:#293F11;

// ***** Typography Tokens *****
@mixin type-hero {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 4.286rem;
  line-height: 72px;
  letter-spacing: -0.02em;
}
@mixin type-h1 {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 2.875rem;
  line-height: 38px;
  letter-spacing: -0.02em;
}
@mixin type-h2 {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 2.286rem;
  line-height: 38px;
  letter-spacing: -0.02em;
}
@mixin type-h3 {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 2rem;
  line-height: 34px;
  letter-spacing: -0.02em;
}
@mixin type-h4 {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 1.714rem;
  line-height: 29px;
}
@mixin type-h5 {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 1.429rem;
  line-height: 24px;
}
@mixin type-h6 {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 0.875rem;
  line-height: 14px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
}
@mixin type-lg {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.286rem;
  line-height: 22px;
}
@mixin type-md {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
}
@mixin type-sm {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.786rem;
  line-height: 15px;
}
@mixin type-lg-italic {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.286rem;
  line-height: 22px;
  font-style: italic;
}
@mixin type-md-italic {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  font-style: italic;
}
@mixin type-sm-italic {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.786rem;
  line-height: 15px;
  font-style: italic;
}
@mixin type-lg-bold {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 1.286rem;
  line-height: 22px;
}
@mixin type-md-bold {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 1rem;
  line-height: 20px;
}
@mixin type-sm-bold {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 0.786rem;
  line-height: 15px;
}
@mixin type-lg-bold-italic {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 1.286rem;
  line-height: 22px;
  font-style: italic;
}
@mixin type-md-bold-italic {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 1rem;
  line-height: 20px;
  font-style: italic;
}
@mixin type-sm-bold-italic {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 0.786rem;
  line-height: 15px;
  font-style: italic;
}
@mixin type-button-lg {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 1.286rem;
  line-height: 22px;
}
@mixin type-button-md {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 1rem;
  line-height: 17px;
}
@mixin type-button-sm {
  font-family: "Avenir", Helvetica, "Arial", sans-serif;
  font-weight: 800;
  font-size: 0.786rem;
  line-height: 13px;
}

// ***** Effects Tokens *****
@mixin shadow-sm {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.15);
}
@mixin shadow-md {
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.1);
}
@mixin shadow-lg {
  box-shadow: 0 16px 38px rgba(0, 0, 0, 0.14), 0 8px 15px rgba(0, 0, 0, 0.1);
}
