@import "src/assets/tokens";
.card {
  margin-top: 50px !important;
  @include shadow-sm;
  border-radius: 2px;
  padding: 2rem;
  h3{
    margin-bottom: 20px;
  }
  h6 {
    margin: 5px 0;
  }
}
.iconText {
  display: flex;
  align-items: center;
  margin: 10px 0;
  svg {
    margin-right: 10px;
  }
}
.detail {
  border-bottom: 1px solid $grayscale-200;
  padding: 10px 0;
  .iconText{
    margin: 7px 0;
  }
}
.emailConfirmation {
  margin: 20px 0;
  color: $grayscale-400;
}
