@import "src/assets/tokens";

.card {
  min-width: 550px;
  margin-top: 50px !important;
  border-radius: 2px;
  @include shadow-sm;
  padding: 4rem 2rem;
  h3{
    margin-bottom: 20px;
  }
  p{
    color: $grayscale-400;
    margin-left: 10px;
  }
}
.iconText {
  display: flex;
  align-items: center;
  margin: 10px 0;
  svg {
      margin-right: 10px;
    }
}
