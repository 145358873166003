@import "src/assets/tokens";
.sideNav {
  background: $primary-100;
  padding: 1rem;
}
.tabContainer {
  padding-top: 2rem;
}
.appointmentsList {
  color: $primary-400;
  padding: 0 1rem 1rem;
  p{
    margin: 0 0 0.5rem 2.5rem;
  }
}

@media (max-width: 767px) {
  h2{
    text-align: center;
    @include type-h3;
  }
  .sideNav{
    padding: 0.5rem 0.5rem 0;
    max-height: 110px;
    box-sizing: content-box;
    .tabContainer{
      padding: 0;
      text-align: center;
      display: flex;
    }
    .appointmentsList{
      display: none;
    }
  }
}
