@import "src/assets/tokens";
.label{
  display: block;
  width: 100%;
  color: $primary-600;
  @include type-sm-bold;
  font-size: 13px;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.disabled {
  color: $grayscale-400;
}

.input {
  border: 1px solid $primary-300;
  padding: 7px 8px;
  @include type-md;
  width: 100%;
  max-height: 35px;
  border-radius: 2px;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 20px;
  &:active, &:focus, &:hover {
    border: 1px solid $primary-400;
  }
  &::placeholder {
    color: $primary-300;
  }
  &:disabled {
    border: 1px solid $grayscale-200;
    color: $grayscale-300;
    &::placeholder {
      color: $grayscale-300;
    }
  }
}
