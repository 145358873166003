@import "src/assets/tokens";
.bottomBar {
  z-index: 2000;
  min-height: 35px;
  width: 100%;
  background: $grayscale-000;
  position: fixed;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid $grayscale-100;
  box-shadow: 2px -2px 5px 1px rgba(0,0,0,0.15);
  -webkit-box-shadow: 2px -2px 5px 1px rgba(0,0,0,0.15);
  -moz-box-shadow: 2px -2px 5px 1px rgba(0,0,0,0.15);
}

@media (max-width: 767px) {
  .bottomBar{
    left: 0;
  }
}
