@import "src/assets/tokens";

.errorContainer {
   padding: 5rem;
   background: $grayscale-000;
   text-align: center;
   h4{
    color: $grayscale-500;
     text-align: center;
     margin: 0 auto;
   }
   svg {
    margin-bottom: 20px;
   }
}
